export const SANDBOX = process.env.NEXT_PUBLIC_PRODUCTION !== "true"

const staggingConfig = {
  API_DOMAIN: "de.dalma.dev",
  PRODUCT: "dalma-de-latest",
  STRIPE_PUB_KEY:
    "pk_test_51NQlSSKa2EkLEjykcHbpfwOoyWuB5csaK67zCvrOsBvr68eCQ2YWi6rqjfarMzVflB6SfuTSxr8VbHlXbYaEGQpK00Zkcd9hV7",
  BREEDS_BFF_URL: "https://breeds.de.dalma.dev/latest",
  BREEDS_BFF_VERSION: "v1",
  BREEDS_BFF_KEY: "latest-breeds-de-bff-is-awesome",
}

export const LEAD_API_KEY = SANDBOX
  ? "TBaxn8kIrU3b1Di3b8Iys6LNCiHPPxz271u8AmWc"
  : "GeDeDwbulO2qObU7xuGdc8RKqKiag3kzaOoWm0fU"

const prodConfig = {
  API_DOMAIN: "de.dalma.tech",
  PRODUCT: "dalma-de",
  STRIPE_PUB_KEY:
    "pk_live_51N0JjQJXtytOBfNMRYws8nqJZywzs01zYu8U98EHUzoKTFeZ3aEO5XVXSygKgFXivNwLHR6AYObWnghmiXkLuaJs00UQ7Kz1K0",
  BREEDS_BFF_URL: "https://breeds.de.dalma.tech/prod",
  BREEDS_BFF_VERSION: "v1",
  BREEDS_BFF_KEY: "prod-breeds-de-bff-is-awesome",
}

export const config = SANDBOX ? staggingConfig : prodConfig

export const googleApiKey = SANDBOX
  ? "AIzaSyDcixeeF7RtO-sGkkRHocOUMpdgMUtvZbE"
  : "AIzaSyDcixeeF7RtO-sGkkRHocOUMpdgMUtvZbE"

export const TRACKJS_TOKEN = "d36b7e08092f4b548b6b4e15f65001da"
