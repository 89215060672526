import { addPet, createQuote, sendUtm } from "@/shared/http"
import { Pet, Utms } from "@/shared/types/global-store"
import { SyncBackPet } from "@/shared/types/lead-api-types"
import { hasUtm } from "@/shared/utils/helpers"
import { useMutation } from "@tanstack/react-query"
import { useSearchParams } from "next/navigation"
import { TrackJS } from "trackjs"

function adaptPetToBackend(pet?: Pet) {
  if (!pet) {
    return undefined
  }
  return {
    idx: pet.idx,
    name: pet.name,
    birthday: pet.birthday,
    meta: {
      prevention_version: "v2",
      subscription_start_at: "asap",
    },
    uuid: pet.uuid || "11223344",
    uuid_type: pet.uuid_type,
    sexe: pet.sex,
    type: pet.type,
    breed: pet.breed || { race: "mixed_breed" },
    pricing: undefined,
  }
}

export const useCreateQuote = () => {
  const params = useSearchParams()
  const utmParams: Utms = {
    utm_source: params.get("utm_source") || "",
    utm_medium: params.get("utm_medium") || "",
    utm_campaign: params.get("utm_campaign") || "",
    utm_content: params.get("utm_content") || "",
    utm_term: params.get("utm_term") || "",
    utm_comparateur: params.get("utm_comparateur") || "",
    gclid: params.get("gclid") || "",
    compclid: params.get("compclid") || "",
    at_gd: params.get("at_gd") || "",
    advertiser_id: params.get("advertiserId") || "",
    channel_id: params.get("channelId") || "",
    market: params.get("market") || "",
    click_id: params.get("clickId") || "",
    click_ref: params.get("clickRef") || "",
    s_id: params.get("s_id") || "",
    affiliate_gclid: params.get("affiliate_gclid") || params.get("affiliateGclid") || "",
  }
  const referrer = params.get("utm_referrer") || ""
  return useMutation({
    mutationKey: ["create-quote"],
    mutationFn: async ({ quoteId, petIdx }: { quoteId?: string; petIdx?: number }) => {
      const quote = await createQuote(quoteId, petIdx)
      if (hasUtm(utmParams)) {
        await sendUtm(quoteId || "", utmParams, referrer).catch((error: Error) =>
          TrackJS.track(error)
        )
      }
      return quote
    },
    retry: 0,
  })
}

export const useAddPet = () => {
  return useMutation({
    mutationKey: ["add-pet"],
    mutationFn: async ({ quoteId, pet }: { quoteId?: string; pet?: Pet }) => {
      const response = await addPet(quoteId, adaptPetToBackend(pet) as SyncBackPet)

      return response
    },
    retry: 0,
  })
}
