import { syncBack } from "@/shared/http"
import { SyncBackBody } from "@/shared/types/lead-api-types"
import { SubscriptionFlowContext } from "@/store/machine"
import { useMutation } from "@tanstack/react-query"

export function buildSyncBackBody(ctx: SubscriptionFlowContext): SyncBackBody {
  return {
    product: "dalma-de",
    meta: {
      quote_id: ctx.meta.quote_id,
      referrer: "referer",
      user_id: ctx.meta.user_id,
      promo_code: ctx.meta.promo_code,
      ab_tests: ctx.meta.ab_tests,
      start_date: ctx.meta.start_date,
      subscription_mode: ctx.meta.subscription_mode || "ASAP",
    },
    marketing: {
      referrer_details: ctx.marketing.referrer_details,
      referrer_type: ctx.marketing.referrer_type,
    },
    owner: {
      birthday: ctx.owner.birthday || null,
      firstname: ctx.owner.firstname || null,
      lastname: ctx.owner.lastname || null,
      address: ctx.owner.address || null,
      birthcity: ctx.owner.birthcity || null,
      phone: ctx.owner.phone || null,
      email: ctx.owner.email || null,
    },
    pets: ctx.pets.map((pet) => ({
      idx: pet.idx,
      name: pet.name,
      birthday: pet.birthday,
      meta: {
        prevention_version: "v1",
        subscription_start_at: "asap",
      },
      uuid: pet.uuid,
      uuid_type: pet.uuid_type,
      sexe: pet.sex,
      type: pet.type,
      breed: pet.breed,
      health: {
        status: "healthy",
      },

      ...(pet.pricing && {
        pricing: {
          accepted_formula: "formula_dalma_de",
          options: {
            pack: pet.pricing.pack,
            health_rate: pet.pricing.rate + "%",
            surgery_rate: pet.pricing.rate + "%",
            prevention: pet.pricing.pack === "health" ? pet.pricing.prevention : false,
          },
          coverage: {
            health_limit: pet.pricing.pack === "health" ? pet.pricing.health_limit : "0",
            prevention_limit: pet.pricing.pack === "health" ? pet.pricing.prevention_limit : "0",
            surgery_limit: pet.pricing.surgery_limit,
          },
        },
      }),
    })),
  }
}

export const useSyncBack = () => {
  return useMutation({
    mutationKey: ["sync-back"],
    mutationFn: async (ctx: SubscriptionFlowContext) => {
      const body = buildSyncBackBody(ctx)
      const res = await syncBack(body)

      return res.success
    },
  })
}
