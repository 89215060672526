import type { RAFCodeResponse, WelcomeResponse } from "@/shared/types/myspace-api-types"
import { config } from "@/shared/utils/config"
import ky from "ky"

export const myspaceAPI = ky.create({
  prefixUrl: `https://myspace.${config.API_DOMAIN}/${config.PRODUCT}`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
})

/**
 * URL: `/welcome`
 *
 * METHOD: `GET`
 */
export async function getWelcomeData(quoteId: string, signal?: AbortSignal) {
  return myspaceAPI
    .get("welcome", {
      searchParams: {
        quote_id: quoteId,
      },
      signal,
    })
    .json<WelcomeResponse>()
}

/**
 * URL: `/raf/cod`
 *
 * METHOD: `GET`
 */
export async function getRafData(email: string) {
  return myspaceAPI
    .get(`raf/code`, {
      searchParams: {
        email,
      },
    })
    .json<RAFCodeResponse>()
}
