import { GetBreedsResponse } from "@/shared/types/breed-api-types"
import { Pet } from "@/shared/types/global-store"
import { config } from "@/shared/utils/config"
import ky from "ky"

export const breedAPI = ky.create({
  prefixUrl: `${config.BREEDS_BFF_URL}/${config.BREEDS_BFF_VERSION}`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    "x-api-key": config.BREEDS_BFF_KEY,
  },
})

/**
 * URL: `/breeds`
 *
 * METHOD: `GET`
 */
export async function getBreeds(type?: Pet["type"], location: "DE" = "DE") {
  return breedAPI
    .get("breeds", {
      searchParams: {
        // Little Hack to avoid the `type` param if it's not defined
        ...(Boolean(type) ? { type } : {}),
        location,
      },
    })
    .json<GetBreedsResponse>()
}
