import md5 from "md5"

import { SANDBOX } from "./config"
import { gtag } from "./gtm"
import { ContextFromLocalStorage } from "./helpers"
import { capitalizeFirstLetter } from "./string"

export function trackClick(eventName: string, eventProperties: Object | null = null) {
  gtag({
    event: "amplitude_click",
    eventType: eventName,
    eventProperties: {
      ...eventProperties,
      livemode: !SANDBOX,
    },
  })
}

export function trackMeetingClickInfoAddress() {
  trackClick("meeting_click_info_address", {
    url: window.location.href,
  })
}

export function trackMeetingClickPricing() {
  trackClick("meeting_click_pricing", {
    url: window.location.href,
  })
}

export function trackMeetingClickTopbanner() {
  trackClick("meeting_click_topbanner", {
    url: window.location.href,
  })
}

export function trackAppDownload() {
  trackClick("app_download", {
    url: window.location.href,
  })
}

export function trackLeadAttribution(clickId: string) {
  gtag({
    event: "lead_attribution",
    ClickID: clickId,
  })
}

function formatConversionEventId(petType: string, pack: string) {
  return `${capitalizeFirstLetter(petType)}${pack === "surgery" ? "-OP" : ""}`
}

export function trackWelcomeData(context: ContextFromLocalStorage | undefined) {
  const items = context?.pets?.map((pet) => ({
    type: pet.type,
    op: pet.pricing.pack === "surgery",
    conversionEventId: formatConversionEventId(pet.type, pet.pricing.pack),
  }))
  const quoteId = context?.meta?.quote_id
  
  if (items) {
    items.forEach((item, index) => {
      const conversionEventId = item.conversionEventId
      const orderId = `${quoteId || ""}-${index}`
      if (conversionEventId) {
        (window as any).addrevenue_event(conversionEventId, {'orderId': orderId})
      }
    })
  }

  gtag({
    event: "subscription",
    quote_id: quoteId,
    items,
  })
}

export function trackPricingABTest(variant: any) {
  gtag({ event: "gtm.historyChange", pricingABTestVariant: variant })
}

export function trackPrepackagesABTest(type: "small" | "medium" | "large") {
  trackClick("prepackage", {
    type,
  })
}

export function updateAdtractionDataLayer(context: ContextFromLocalStorage | undefined) {
  const correspondingProductNames = {
    dog: "hund",
    cat: "katze",
    surgery: "op",
    health: "voll",
  }
  const productCategories = context?.pets?.map(
    (pet) =>
      `${correspondingProductNames[pet.type]}${correspondingProductNames[pet.pricing.pack]}_0`
  )
  const formattedProductCategories = productCategories?.join("-")

  gtag({
    transactionId: context?.meta?.quote_id,
    transactionPromoCode: context?.meta?.promo_code || "",
    md5: md5(context?.owner?.email || ""),
    transactionProductCategories: formattedProductCategories,
  })
}